import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import HeaderSEO from '../../../components/partials/HeaderSEO'

import Layout from '../../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

const research451_thank_you = () => {
    const data = useStaticQuery(graphql`
        query Research_451_Thank_you_SEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "451_Research_Thank_You" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <Helmet>
                <meta name="robots" content="noindex" />
                <meta name="robots" content="nofollow" />
            </Helmet>
            <StaticImage
                className="home-fixed-top"
                src="../../../resources/images/Homepage/hero_lens.svg"
                placeholder="blurred"
                alt="Home Lenses"
            />
            <section className="pt-5 mt-5 pb-4">
                <div className="container-1">
                    <div className="row mt-3">
                        <div className="col-md-8 offset-2 text-center pt-0">
                            <StaticImage
                                className="img-fluid w-50 mx-auto d-block"
                                src="../../../resources/images/vectors/undraw_message_sent_1030.svg"
                                placeholder="tracedSVG"
                                alt="enterprise ok"
                            />
                            <h2 className="mt-3">
                                Thanks for downloading our new report with 451 Research!
                            </h2>
                            <p className="hero-description-dark">
                                We&apos;ve sent the file straight to your inbox (make sure to check
                                your junk folder).
                            </p>
                            <p>
                                Interested to see <strong> DataOps</strong> in action? Learn how
                                Playtika gains 300 engineering hours per day using DataOps best
                                practices - <a href="/customers/playtika/">Read Case Study</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-1 pb-5">
                    <div className="row  mt-5 mb-5">
                        <div className="col-md-12">
                            <h2 className="paragraph-title">Need help?</h2>
                        </div>
                        <div className="col-md-4">
                            <div className="shadow p-5">
                                <i
                                    className="fa fa-file-text-o single-icon fa-2x"
                                    aria-hidden="true"
                                ></i>
                                <p className="mt-3">
                                    See our documentation on how to get started with Lenses Box
                                </p>
                                <a
                                    className="link-text f-16"
                                    href="https://docs.lenses.io"
                                    role="button"
                                >
                                    Quick Start →
                                </a>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="p-5">
                                <i className="fa fa-slack single-icon fa-2x" aria-hidden="true"></i>
                                <p className="mt-3">
                                    Speak to us directly on our Community Slack Channel
                                </p>
                                <a
                                    className="link-text f-16"
                                    href="https://launchpass.com/lensesio"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    role="button"
                                >
                                    Join now →
                                </a>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" p-5">
                                <i
                                    className="fa fa-send-o single-icon fa-2x"
                                    aria-hidden="true"
                                ></i>
                                <p className="mt-3">
                                    Send us a message <br />
                                    <br />
                                </p>
                                <a
                                    className="link-text f-16"
                                    href="/contact-us/?s=thank-you-451-research"
                                    role="button"
                                >
                                    Contact →
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-1 pb-5">
                    <div className="row mb-5">
                        <div className="col-md-12">
                            <small>
                                Didn&apos;t get the email? Contact{' '}
                                <a
                                    className="link-text"
                                    href="mailto:info@lenses.io"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    info@lenses.io
                                </a>
                            </small>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
export default research451_thank_you
